@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600&display=swap");

.About {
  width: 100%;
  height: auto;
  padding: 12rem 0;
  width: 94%;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  position: relative;
}

.IconsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4rem;
}

.Socials {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #dd6e8b;
  gap: 2rem;
}

.Socials a {
  color: #dd6e8b;
  transition: 0.25s all ease-in;
}

.Socials a:hover {
  color: red;
}

.AboutBar {
  position: absolute;
  top: -2%;
  left: 50%;
  transform: translateX(-50%);
  width: 0.2rem;
  height: 6.5rem;

  background: #dd6e8b;
  border-radius: 1.5rem;
}

.AboutImage {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.AboutImage img {
  width: 37.8rem;
  height: 55rem;
}

.AboutImageLogo {
  position: absolute;
  bottom: 5%;
  left: 5%;
}

.AboutImageLogo img {
  width: 9rem;
  height: 5.383rem;
}

.AboutContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 57.3rem;
  gap: 4rem;
}

.AboutContentTitle h4 {
  font-family: "Oswald";
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.4rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.29rem;

  color: #dd6e8b;
}

.AboutContentTitle h2 {
  font-family: "Oswald";
  font-style: normal;
  font-weight: normal;
  font-size: 3.6rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  margin-left: -0.15rem;

  color: #000000;
}

.AboutContentTitleBar {
  margin-top: 6rem;
  width: 6.4rem;
  height: 0.3rem;

  background: #dd6e8b;
  border-radius: 1.5rem;
}

.AboutContentText p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;

  color: #000000;
}

.AboutContentBtn {
  width: 16.2rem;
  height: 5.1rem;

  background: #ffffff;
  border: 2px solid #dd6e8b;
  box-sizing: border-box;

  font-family: "Oswald";
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;

  color: #dd6e8b;
  transition: 0.15s all ease-in;
  cursor: pointer;
}

.AboutContentBtn:hover {
  border: 2px solid #fff;
  background-color: #dd6e8b;
  color: #fff;
}

@media only screen and (max-width: 1010px) {
  .About {
    width: 94%;
    gap: 6rem;
  }
  .AboutContent {
    width: 50%;
  }
}

@media only screen and (max-width: 900px) {
  .About {
    width: 90%;
    gap: 4rem;

    display: flex;
    flex-direction: column;
    padding: 8rem 0;
  }
  .AboutContent {
    width: 90%;
    margin: 0 auto;
  }
  .AboutBar {
    top: -1.5%;
  }
}

@media only screen and (max-width: 380px) {
  .AboutBar {
    top: -1%;
    height: 6rem;
  }

  .AboutImage img {
    max-width: 100%;
    height: auto;
  }

  .IconsContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4rem;
  }
}

.IconsContainer a {
  text-decoration: none;
}