.Portfolio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  max-width: 1050px;
  margin: 0 auto;
}

.PortfolioBoxMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.PortfolioBoxMain a {
  text-decoration: none;
}

.PortfolioHeader h6 {
  font-family: "Oswald";
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.4rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.2em;

  color: #dd6e8b;
}

.PortfolioHeader h2 {
  font-family: "Oswald";
  font-style: normal;
  font-weight: normal;
  font-size: 3.2rem;
  line-height: 0rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;

  color: #000000;
  padding-bottom: 2.5rem;
}

.PortfolioBox {
  box-shadow: 0.4rem 0.4rem 1.2rem rgba(0, 0, 0, 0.12);
  border-radius: 0.4rem;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.PortfolioBox img {
  width: 100%;
  min-height: 100%;
  height: auto;
  transition: all 370ms ease-in;
}

#_ImageTitle {
  font-family: "Jost";
  font-size: 1.6rem;
  color: #fff;
  font-weight: 400;
  letter-spacing: 0.2rem;
  border-radius: 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  box-shadow: none;
  width: 85%;
  position: absolute;
  bottom: 1.25rem;
  left: 50%;
  transform: translateX(-50%);

  padding: 1.5rem 0rem;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1.5rem);
}

.PortfolioBox img:hover {
  transform: scale(1.125);
}

.PortfolioBox:hover {
}

.PortfolioHeaderBar {
  width: 6.4rem;
  height: 0.3rem;

  background: #dd6e8b;
}

.PortfolioContent {
  width: 100%;
  height: auto;
  padding: 12rem 0;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  position: relative;
}

.PortfolioContentBottom {
  width: 100%;
  height: auto;
  padding: 0rem 0 12rem 0;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  position: relative;
}

.PortfolioBoxBtn {
  background: #ffffff;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
  max-width: 80%;
  width: 40.2rem;
  height: 7.4rem;
  margin-top: -2.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.3rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;

  color: #000000;
  transition: 0.15s all ease-in;
  cursor: pointer;
}

.PortfolioBoxBtn:hover {
  transform: translateY(-8%);
}

.PortfolioBoxBtn:active {
  transform: translateY(8%);
}

@media only screen and (max-width: 1140px) {
  .Portfolio {
    width: 94%;
  }
  .PortfolioBoxBtn {
    height: 5.4rem;
    margin-top: -2.25rem;
    padding-left: 8%;
    font-size: 1.4rem;
  }
  .PortfolioContent {
    justify-content: space-between;
    padding: 10rem 0rem;
    gap: 0;
  }

  .PortfolioContentBottom {
    justify-content: space-between;
    padding: 0rem 0 10rem 0;
    gap: 0;
  }
}

@media only screen and (max-width: 900px) {
  .Portfolio {
    width: 81%;
  }

  .PortfolioContent {
    display: flex;
    flex-direction: column;
    padding: 4rem 0rem;
    gap: 4rem;
  }

  .PortfolioContentBottom {
    display: flex;
    flex-direction: column;
    padding: 0rem 0 4rem 0;
    gap: 4rem;
  }

  .PortfolioBoxBtn {
    width: 80%;
  }
}

@media only screen and (max-width: 640px) {
  .PortfolioContent {
    display: flex;
    flex-direction: column;
    padding: 6rem 0rem;
    gap: 6rem;
  }

  .PortfolioContentBottom {
    display: flex;
    flex-direction: column;
    padding: 0rem 0 6rem 0;
    gap: 6rem;
  }

  .PortfolioBoxBtn {
    height: 6.4rem;
    margin-top: -2.75rem;
    padding-left: 8%;
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 400px) {
  .PortfolioContent {
    display: flex;
    flex-direction: column;
    padding: 5rem 0rem;
    gap: 5rem;
  }

  .PortfolioContentBottom {
    display: flex;
    flex-direction: column;
    padding: 0rem 0 5rem 0;
    gap: 5rem;
  }

  .PortfolioBoxBtn {
    height: 6.4rem;
    margin-top: -2.75rem;
    padding-left: 8%;
    font-size: 1.6rem;
  }
}

/* Portfolio Images */
.PortfolioImages {
  position: fixed;
  top: 0%;
  right: 0%;
  max-width: 80vw;
  width: 56.5rem;
  height: 100vh;

  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2.5rem);
  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem;
  overflow-y: scroll;
}

.PortfolioImages::-webkit-scrollbar-thumb {
  opacity: 1;
  width: 1rem;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #f7c0b9;
  border-radius: 0.8rem;
}

.PortfolioImages::-webkit-scrollbar {
  opacity: 1;
  width: 1.5rem;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #fff;
}

@media only screen and (max-width: 450px) {
  .PortfolioImages::-webkit-scrollbar-thumb {
    display: none;
  }

  .PortfolioImages::-webkit-scrollbar {
    display: none;
  }
}
