.Video {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);

    position: fixed;
    top: 0%;
    left: 0%;

    z-index: 999999999999;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.VideoPlayer {
    max-width: 100%;
    height: auto;
}

.VideoClose {
    width: 5rem;
    height: 5rem;
    background: #fff;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
}