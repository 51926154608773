._PortfolioPage {
  background-color: rgba(221,110,139, 0.18);
  min-height: 100vh;
  height: auto;
}

._PortfolioPageLogo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 0rem;
    padding-bottom: 2.75rem;
}



.Controls {
  width: auto;
  height: 5rem;
  box-shadow: 0.6rem 0.6rem 1.2rem rgba(0, 0, 0, 0.12);
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 2.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}

.Controls div {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 3.5rem;
  background-color: #dd6e8b;
  height: 3.5rem;
  border-radius: 50%;
  box-shadow: 0.6rem 0.6rem 1.2rem rgba(0, 0, 0, 0.12);
}

._PortfolioPageLogo img {
    width: 12.75rem;
    height: auto;
}

._PortfolioPageFooter {
  margin: 0;
  font-weight: 500;
  font-family: 'Jost';
  letter-spacing: 0.1rem;
  font-size: 1.6rem;
  padding-top: 8rem;
  padding-bottom: 4rem;
  text-align: center;
  color: #dd6e8b;
}

._PortfolioPageHeader {
  font-size: 2.2rem;
  font-family: "Jost";
  letter-spacing: 0.1rem;
  padding-bottom: 2rem;
  color: #dd6e8b;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 1.5rem;
}

._PortfolioPageHeader span {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scaleX(-1);
}


.gallery {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-width: 25%;
  -moz-column-width: 25%;
  column-width: 25%;
  padding: 0 1.2rem;
}

.gallery .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 1.2rem;
}

.gallery .pics:hover {
    filter: opacity(0.8);
}

@media (max-width: 991px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}


/* Model */ 

.model  {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
  visibility: visible;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 9999;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 2rem 0 2rem;
  margin: 0 auto;
}

.model.open #ClosePortfolioIcon {
  position: fixed;
  top: 2rem;
  right: 4rem;
  cursor: pointer;
  z-index: 99999999;
}


.PortfolioExplainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem;
  margin-bottom: 6rem;
}

.PortfolioExplainer div {
  max-width: 60rem;
  font-family: "Jost";
  font-size: 1.7rem;
  text-align: center;
  letter-spacing: 0.1rem;
  line-height: 2rem;
}

@media (max-width: 480px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }

  .PortfolioExplainer div {
    text-align: left;
  }
}