.Map {
  width: 40rem;
  height: 29rem;
  background-color: #fff;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
  position: relative;
  background-image: url("https://source.unsplash.com/BJXAxQ1L7dI");
  background-size: cover;
  max-width: 100%;
}

.MapScreen {
  position: absolute;
  top: 0%;
  left: 0%;
  background-color: rgba(0, 0, 0, 0.12);
  width: 100%;
  min-height: 100%;
}

.Map button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.5rem 2.75rem;
  outline: none;
  border: none;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.1rem;
  color: #fff;
  background-color: #dd6e8b;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}



@media only screen and (max-width: 450px) {
  .Map {
    height: 25rem;
  }
}

.Contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  max-width: 1050px;
  margin: 0 auto;
}

.ContactHeader h6 {
  font-family: "Oswald";
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.4rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.2em;

  color: #dd6e8b;
}

.ContactHeader h2 {
  font-family: "Oswald";
  font-style: normal;
  font-weight: normal;
  font-size: 3.2rem;
  line-height: 0rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;

  color: #000000;
  padding-bottom: 2.5rem;
}

.ContactHeaderBar {
  width: 6.4rem;
  height: 0.3rem;

  background: #dd6e8b;
}

.ContactContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 1140px) {
  .Contact {
    width: 94%;
  }
}

@media only screen and (max-width: 1140px) {
  .Contact {
    width: 81%;
  }
}

@media only screen and (max-width: 1060px) {
  .ContactContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

.Form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12rem 0rem;
  gap: 2rem;
}

.Form input {
  width: 40rem;
  max-width: 80vw;
  padding: 1.5rem 2.75rem;
  outline: none;
  border: none;
  border-bottom: 2px solid #dd6e8b;
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 0.2rem;
}

.Form button {
  margin-top: 1.5rem;
  padding: 1.25rem 2.75rem;
  outline: none;
  border: none;
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.2rem;
  color: #fff;
  background-color: #dd6e8b;
  cursor: pointer;
}

.Form .MsgSucess {
  margin-top: 1.5rem;
  padding: 1.25rem 2.75rem;
  outline: none;
  border: none;
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.2rem;
  color: #fff;
  background-color: #dd6e8b;
}

.SucessContext {
  font-size: 1.5rem;
  font-weight: 500;
  color: #dd6e8b;
}

@media only screen and (max-width: 1140px) {
  .Form {
    padding: 10rem 0rem;
  }
}

@media only screen and (max-width: 1140px) {
  .Form {
    padding: 8rem 0rem;
  }
}
