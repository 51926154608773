@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600&display=swap");

.Nav {
  position: fixed;
  top: 0%;
  left: 0%;
  max-width: 80vw;
  width: 36.5rem;
  height: 100vh;

  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(25px);
  z-index: 999999;
}

.NavContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 48%;
  left: 10%;
  transform: translateY(-50%);
  gap: 2.8rem;
}

.NavContent div {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0.29rem;

  color: #ffffff;
  border-radius: 0.8rem;
  width: 10rem;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: 0.4s all ease-out;

  cursor: pointer;
}

.NavContent div:hover {
  transform: translateX(2rem);
}

.NavContent div a {
  text-decoration: none;
  color: #fff;
}






.NavFooterBar {
  width: 5rem;
  height: 0.2rem;

  background: #f7c0b9;
  border-radius: 1.5rem;
}

.NavLogo {
  position: absolute;
  top: 0%;
  left: 10%;
  padding-top: 4rem;
}

.NavLogo img {
    width: 9rem;
    height: 5.383rem;
}

.NavFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 80%;
  left: 10%;
  transform: translateY(-50%);
  gap: 0.75rem;
  font-family: "Jost";
  font-style: normal;
  font-weight: 300;
  font-size: 1.2rem;
  display: flex;
  align-items: flex-start;
  letter-spacing: 0.15rem;
}

@media only screen and (max-width: 650px) {
  .NavContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 48%;
    left: 8%;
    transform: translateY(-50%);
    gap: 2.8rem;
  }

  .NavContent div {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.4rem;

    color: #ffffff;
  }

  .NavLogo {
    position: absolute;
    top: 0%;
    left: 8%;
    padding-top: 2.75rem;
  }

  .NavFooter {
    top: 80%;
    left: 8%;
    transform: translateY(-50%);
    gap: 0.75rem;
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
  }
}

