.Footer {
    width: 100%;
    min-height: 14rem;
    background-color: #fff;
    margin-top: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 20px 6px 12px rgba(0, 0, 0, 0.4);
  }

  .ScrollTop {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 5rem;
    height: 5rem;
    background-color: #fff;
    box-shadow: 0.6rem 0.6rem 1.2rem rgba(0, 0, 0, 0.12);
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    z-index: 9999999999999;
  }
  
  .FooterContent {
    max-width: 1064px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    font-family: "Jost";
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.1rem;
    color: rgba(0, 0, 0, 0.7);
  }

  .FooterContent div img {
      width: 9rem;
      height: 5.383rem;
  }
  
  @media only screen and (max-width: 1140px) {
      .FooterContent {
        width: 94%;
      }
    }
    
    @media only screen and (max-width: 1140px) {
      .FooterContent {
        width: 81%;
      }
    }
    
    
    @media only screen and (max-width: 1060px) {
      .FooterContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
      }
  
      .Footer {
          height: 18rem;
      }
    }
  