.Testimonials {
    width: 100%;
    height: auto;
    width: 94%;
    max-width: 1050px;
    margin: 0 auto;
  }
  
  .TestimonialsHeader h6 {
    font-family: "Oswald";
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2.4rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.2em;
  
    color: #dd6e8b;
  }
  
  .TestimonialsHeader h2 {
    font-family: "Oswald";
    font-style: normal;
    font-weight: normal;
    font-size: 3.2rem;
    line-height: 0rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
  
    color: #000000;
    padding-bottom: 2.5rem;
  }
  
  .TestimonialsHeaderBar {
    width: 6.4rem;
    height: 0.3rem;
  
    background: #dd6e8b;
  }
  
  .TestimonialsContent {
    width: 100%;
    height: auto;
    padding: 12rem 0;
    margin: 0 auto;
  
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10rem;
    position: relative;
  }
  
  .TestimonialsCards {
    width: 100%;
    height: auto;
  
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6rem;
    padding: 5rem 0;
  }
  
  .TestimonialsCard {
    width: 40%;
    height: auto;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  
  .TestimonialsCardText {
    width: 50%;
    min-height: 55rem;
    height: auto;
  
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 0.8rem;
    background-color: #fffbf9;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    padding: 10rem 2rem;
    position: relative;
  }
  
  .TestimonialsCard .Card {
    width: 100%;
    height: 11.1rem;
  
    background: #c4c4c4;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 0.8rem;
    background-color: #d5d6ea;
  
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 2rem;
    gap: 2rem;
  
    color: #000000;
    font-family: "Oswald";
  }
  
  .CardImg {
    width: 7.9rem;
    height: 7.9rem;
    border-radius: 50%;
    background-color: aqua;
    overflow: hidden;
  }
  
  .CardImg img {
    width: 100%;
    height: auto;
  }
  
  .Card h2 {
    margin: 0;
    font-size: 1.4rem;
    font-style: normal;
  
    letter-spacing: 0.1em;
  
    color: #000000;
    font-weight: 400;
  }
  
  .Card {
    transition: 0.2s all ease;
  }
  
  .Card:hover {
    transform: translateX(-5rem);
  }
  
  .Card p {
    margin: 0;
    font-size: 1.3rem;
    font-weight: 300;
    letter-spacing: 0.1em;
  }
  
  .CardText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
  }
  
  .TestimonialsCardText .TestimonialsAllText {
    min-width: 25rem;
    width: 80%;
  
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.05em;
  
    color: #343738;
  }
  
  .TestimonialsCardText p {
    position: absolute;
    top: 2rem;
    left: 4rem;
  }
  
  @media only screen and (max-width: 1140px) {
    .Testimonials {
      width: 94%;
    }
  }
  
  @media only screen and (max-width: 900px) {
    .Testimonials {
      width: 81%;
    }
  
    .TestimonialsCards {
      width: 100%;
      height: auto;
  
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 6rem;
      padding: 5rem 0;
    }
  
    .TestimonialsCard {
      width: 80%;
    }
  
    .TestimonialsCardText {
      width: 80%;
    }
  }
  
  @media only screen and (max-width: 450px) {
  
    .TestimonialsCard {
      width: 95%;
    }
  
    .TestimonialsCardText {
      width: 100%;
    }
  }
  