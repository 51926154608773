@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800&display=swap");

.Header {
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.HeaderScreen {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  color: white;
  position: relative;
}

.HeaderContainer {
  /* max-width: 1250px; */
  width: 94%;
  margin: 0 auto;
}

.VideoButton {
  width: 5.1rem;
  height: 5.1rem;
  border-radius: 50%;

  background: rgba(72, 72, 72, 0.5);
  backdrop-filter: blur(25px);

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 2rem;
}

.InnerButton {
  width: 3.2rem;
  height: 3.2rem;

  background: #ffffff;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.InnerButton span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.25rem;
}

.HeaderTop div {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.HeaderTop {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 14rem;
}

.HeaderTopLogo img {
  width: 9rem;
  height: 5.385rem;
}

.HeaderTopOther {
  position: fixed;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 11rem;
  background-color: white;
  z-index: 9999;
  box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.25);
}

.HeaderTopOtherContainer {
  width: 94%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.HeaderTopOther .HeaderTopOtherContainer div {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.HeaderContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 55%;
  left: 3%;
  transform: translateY(-50%);
}

.HeaderContentBar {
  width: 6.5rem;
  height: 0.2rem;

  background: #f1a5a5;
  border-radius: 1.5rem;
}

.HeaderContentTitle {
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: 0.8rem;

  color: #f1a5a5;
  padding-top: 1rem;
}

.HeaderContentName {
  font-family: "Jost";
  font-weight: 600;
  font-size: 6.4rem;
  line-height: 7.4rem;
  /* identical to box height */

  letter-spacing: 0.4rem;

  color: #ffffff;
  padding-bottom: 5.3rem;
  padding-top: 2.75rem;
}

.HeaderContentBtn {
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2rem;

  color: #ffffff;
  width: 17.1rem;
  height: 5rem;

  border: 2px solid #f1a5a5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.25s all ease-in;
}

.HeaderContentBtn:hover {
  border: 2px solid #fff;
  background-color: #fff;
  color: #f1a5a5;
}

@media only screen and (max-width: 1000px) {
  .HeaderContentTitle {
    font-size: 1.6rem;
    line-height: 2.2rem;
    letter-spacing: 0.8rem;
    padding-top: 0.75rem;
  }

  .HeaderContentName {
    font-size: 5.4rem;
    letter-spacing: 0.4rem;
    padding-bottom: 4.75rem;
    padding-top: 2.75rem;
  }

  .HeaderContentBar {
    width: 4.5rem;
  }
  .HeaderTop {
    height: 12rem;
  }
}

@media only screen and (max-width: 650px) {
  /* .Header {
    background-image: url("../../public/images/header.jpg");
  } */
  .HeaderScreen {
    background: rgba(0, 0, 0, 0.4);
  }
  .HeaderContentTitle {
    font-size: 1.4rem;
    line-height: 2.2rem;
    letter-spacing: 0.8rem;
    padding-top: 0.5rem;
  }

  .HeaderContentName {
    font-size: 4.4rem;
    letter-spacing: 0.4rem;
    padding-bottom: 4.75rem;
    padding-top: 2rem;
    line-height: 6.25rem;
    color: #fff;
  }

  .HeaderContentBtn {
    font-size: 1.4rem;
    letter-spacing: 0.2rem;

    color: #ffffff;
    width: 16rem;
    height: 4.5rem;
  }
  .HeaderTop {
    height: 10rem;
  }
  .HeaderContainer {
    /* max-width: 1250px; */
    width: 90%;
    margin: 0 auto;
  }
  .HeaderContent {
    top: 55%;
    left: 5%;
    transform: translateY(-50%);
  }
}

/*Scroll Animation*/
.scrolldown {
  position: absolute;
  top: 90%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.downbox {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.downbox span {
  display: block;
  width: 1.8rem;
  height: 1.8rem;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin: -10px;
  -webkit-animation: animate 2s infinite;
  animation: animate 2s infinite;
}

.downbox span:nth-child(2) {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.downbox span:nth-child(3) {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

@-webkit-keyframes animate {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-20px, -20px);
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(20px, 20px);
    transform: rotate(45deg) translate(20px, 20px);
  }
}

@keyframes animate {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-20px, -20px);
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(20px, 20px);
    transform: rotate(45deg) translate(20px, 20px);
  }
}

/* Mobile Header */

@media only screen and (max-width: 1060px) {
  .line1 {
    font-size: 2.8rem;
  }

  .line2 {
    font-size: 4.8rem;
  }

  .line3 {
    font-size: 4.8rem;
  }

  .line3 span {
    font-size: 2.8rem;
  }
}

@media only screen and (max-width: 750px) {
  .line1 {
    font-size: 2.8rem;
  }

  .line2 {
    font-size: 4.2rem;
  }

  .line3 {
    font-size: 4.2rem;
  }

  .line3 span {
    font-size: 2.8rem;
  }
}

@media only screen and (max-width: 450px) {
  .line1 {
    font-size: 2.4rem;
  }

  .line2 {
    font-size: 3.8rem;
  }

  .line3 {
    font-size: 3.8rem;
  }

  .line3 span {
    font-size: 2.8rem;
  }
}

@media only screen and (max-width: 500px) {
  .HeaderTop div {
    cursor: default;
  }
  .HeaderTopOther .HeaderTopOtherContainer div {
    cursor: default;
  }
  .HeaderContentBtn {
    cursor: default;
  }
}

.HeaderContent a {
  text-decoration: none;
}
