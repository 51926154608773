@import url(https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

body::-webkit-scrollbar-thumb {
  opacity: 1;
  width: 1rem;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color:#DD6E8B;
}

body::-webkit-scrollbar {
  opacity: 1;
  width: 1.5rem;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #fff;
}

.Header {
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.HeaderScreen {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  color: white;
  position: relative;
}

.HeaderContainer {
  /* max-width: 1250px; */
  width: 94%;
  margin: 0 auto;
}

.VideoButton {
  width: 5.1rem;
  height: 5.1rem;
  border-radius: 50%;

  background: rgba(72, 72, 72, 0.5);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 2rem;
}

.InnerButton {
  width: 3.2rem;
  height: 3.2rem;

  background: #ffffff;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.InnerButton span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.25rem;
}

.HeaderTop div {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.HeaderTop {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 14rem;
}

.HeaderTopLogo img {
  width: 9rem;
  height: 5.385rem;
}

.HeaderTopOther {
  position: fixed;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 11rem;
  background-color: white;
  z-index: 9999;
  box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.25);
}

.HeaderTopOtherContainer {
  width: 94%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.HeaderTopOther .HeaderTopOtherContainer div {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.HeaderContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 55%;
  left: 3%;
  transform: translateY(-50%);
}

.HeaderContentBar {
  width: 6.5rem;
  height: 0.2rem;

  background: #f1a5a5;
  border-radius: 1.5rem;
}

.HeaderContentTitle {
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: 0.8rem;

  color: #f1a5a5;
  padding-top: 1rem;
}

.HeaderContentName {
  font-family: "Jost";
  font-weight: 600;
  font-size: 6.4rem;
  line-height: 7.4rem;
  /* identical to box height */

  letter-spacing: 0.4rem;

  color: #ffffff;
  padding-bottom: 5.3rem;
  padding-top: 2.75rem;
}

.HeaderContentBtn {
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2rem;

  color: #ffffff;
  width: 17.1rem;
  height: 5rem;

  border: 2px solid #f1a5a5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.25s all ease-in;
}

.HeaderContentBtn:hover {
  border: 2px solid #fff;
  background-color: #fff;
  color: #f1a5a5;
}

@media only screen and (max-width: 1000px) {
  .HeaderContentTitle {
    font-size: 1.6rem;
    line-height: 2.2rem;
    letter-spacing: 0.8rem;
    padding-top: 0.75rem;
  }

  .HeaderContentName {
    font-size: 5.4rem;
    letter-spacing: 0.4rem;
    padding-bottom: 4.75rem;
    padding-top: 2.75rem;
  }

  .HeaderContentBar {
    width: 4.5rem;
  }
  .HeaderTop {
    height: 12rem;
  }
}

@media only screen and (max-width: 650px) {
  /* .Header {
    background-image: url("../../public/images/header.jpg");
  } */
  .HeaderScreen {
    background: rgba(0, 0, 0, 0.4);
  }
  .HeaderContentTitle {
    font-size: 1.4rem;
    line-height: 2.2rem;
    letter-spacing: 0.8rem;
    padding-top: 0.5rem;
  }

  .HeaderContentName {
    font-size: 4.4rem;
    letter-spacing: 0.4rem;
    padding-bottom: 4.75rem;
    padding-top: 2rem;
    line-height: 6.25rem;
    color: #fff;
  }

  .HeaderContentBtn {
    font-size: 1.4rem;
    letter-spacing: 0.2rem;

    color: #ffffff;
    width: 16rem;
    height: 4.5rem;
  }
  .HeaderTop {
    height: 10rem;
  }
  .HeaderContainer {
    /* max-width: 1250px; */
    width: 90%;
    margin: 0 auto;
  }
  .HeaderContent {
    top: 55%;
    left: 5%;
    transform: translateY(-50%);
  }
}

/*Scroll Animation*/
.scrolldown {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.downbox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.downbox span {
  display: block;
  width: 1.8rem;
  height: 1.8rem;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  transform: rotate(45deg);
  margin: -10px;
  -webkit-animation: animate 2s infinite;
  animation: animate 2s infinite;
}

.downbox span:nth-child(2) {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.downbox span:nth-child(3) {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

@-webkit-keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

/* Mobile Header */

@media only screen and (max-width: 1060px) {
  .line1 {
    font-size: 2.8rem;
  }

  .line2 {
    font-size: 4.8rem;
  }

  .line3 {
    font-size: 4.8rem;
  }

  .line3 span {
    font-size: 2.8rem;
  }
}

@media only screen and (max-width: 750px) {
  .line1 {
    font-size: 2.8rem;
  }

  .line2 {
    font-size: 4.2rem;
  }

  .line3 {
    font-size: 4.2rem;
  }

  .line3 span {
    font-size: 2.8rem;
  }
}

@media only screen and (max-width: 450px) {
  .line1 {
    font-size: 2.4rem;
  }

  .line2 {
    font-size: 3.8rem;
  }

  .line3 {
    font-size: 3.8rem;
  }

  .line3 span {
    font-size: 2.8rem;
  }
}

@media only screen and (max-width: 500px) {
  .HeaderTop div {
    cursor: default;
  }
  .HeaderTopOther .HeaderTopOtherContainer div {
    cursor: default;
  }
  .HeaderContentBtn {
    cursor: default;
  }
}

.HeaderContent a {
  text-decoration: none;
}

.Nav {
  position: fixed;
  top: 0%;
  left: 0%;
  max-width: 80vw;
  width: 36.5rem;
  height: 100vh;

  background: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  z-index: 999999;
}

.NavContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 48%;
  left: 10%;
  transform: translateY(-50%);
  grid-gap: 2.8rem;
  gap: 2.8rem;
}

.NavContent div {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0.29rem;

  color: #ffffff;
  border-radius: 0.8rem;
  width: 10rem;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: 0.4s all ease-out;

  cursor: pointer;
}

.NavContent div:hover {
  transform: translateX(2rem);
}

.NavContent div a {
  text-decoration: none;
  color: #fff;
}






.NavFooterBar {
  width: 5rem;
  height: 0.2rem;

  background: #f7c0b9;
  border-radius: 1.5rem;
}

.NavLogo {
  position: absolute;
  top: 0%;
  left: 10%;
  padding-top: 4rem;
}

.NavLogo img {
    width: 9rem;
    height: 5.383rem;
}

.NavFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 80%;
  left: 10%;
  transform: translateY(-50%);
  grid-gap: 0.75rem;
  gap: 0.75rem;
  font-family: "Jost";
  font-style: normal;
  font-weight: 300;
  font-size: 1.2rem;
  display: flex;
  align-items: flex-start;
  letter-spacing: 0.15rem;
}

@media only screen and (max-width: 650px) {
  .NavContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 48%;
    left: 8%;
    transform: translateY(-50%);
    grid-gap: 2.8rem;
    gap: 2.8rem;
  }

  .NavContent div {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.4rem;

    color: #ffffff;
  }

  .NavLogo {
    position: absolute;
    top: 0%;
    left: 8%;
    padding-top: 2.75rem;
  }

  .NavFooter {
    top: 80%;
    left: 8%;
    transform: translateY(-50%);
    grid-gap: 0.75rem;
    gap: 0.75rem;
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
  }
}


.Video {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);

    position: fixed;
    top: 0%;
    left: 0%;

    z-index: 999999999999;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
}

.VideoPlayer {
    max-width: 100%;
    height: auto;
}

.VideoClose {
    width: 5rem;
    height: 5rem;
    background: #fff;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
}
.About {
  width: 100%;
  height: auto;
  padding: 12rem 0;
  width: 94%;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10rem;
  gap: 10rem;
  position: relative;
}

.IconsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 4rem;
  gap: 4rem;
}

.Socials {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #dd6e8b;
  grid-gap: 2rem;
  gap: 2rem;
}

.Socials a {
  color: #dd6e8b;
  transition: 0.25s all ease-in;
}

.Socials a:hover {
  color: red;
}

.AboutBar {
  position: absolute;
  top: -2%;
  left: 50%;
  transform: translateX(-50%);
  width: 0.2rem;
  height: 6.5rem;

  background: #dd6e8b;
  border-radius: 1.5rem;
}

.AboutImage {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.AboutImage img {
  width: 37.8rem;
  height: 55rem;
}

.AboutImageLogo {
  position: absolute;
  bottom: 5%;
  left: 5%;
}

.AboutImageLogo img {
  width: 9rem;
  height: 5.383rem;
}

.AboutContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 57.3rem;
  grid-gap: 4rem;
  gap: 4rem;
}

.AboutContentTitle h4 {
  font-family: "Oswald";
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.4rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.29rem;

  color: #dd6e8b;
}

.AboutContentTitle h2 {
  font-family: "Oswald";
  font-style: normal;
  font-weight: normal;
  font-size: 3.6rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  margin-left: -0.15rem;

  color: #000000;
}

.AboutContentTitleBar {
  margin-top: 6rem;
  width: 6.4rem;
  height: 0.3rem;

  background: #dd6e8b;
  border-radius: 1.5rem;
}

.AboutContentText p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;

  color: #000000;
}

.AboutContentBtn {
  width: 16.2rem;
  height: 5.1rem;

  background: #ffffff;
  border: 2px solid #dd6e8b;
  box-sizing: border-box;

  font-family: "Oswald";
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;

  color: #dd6e8b;
  transition: 0.15s all ease-in;
  cursor: pointer;
}

.AboutContentBtn:hover {
  border: 2px solid #fff;
  background-color: #dd6e8b;
  color: #fff;
}

@media only screen and (max-width: 1010px) {
  .About {
    width: 94%;
    grid-gap: 6rem;
    gap: 6rem;
  }
  .AboutContent {
    width: 50%;
  }
}

@media only screen and (max-width: 900px) {
  .About {
    width: 90%;
    grid-gap: 4rem;
    gap: 4rem;

    display: flex;
    flex-direction: column;
    padding: 8rem 0;
  }
  .AboutContent {
    width: 90%;
    margin: 0 auto;
  }
  .AboutBar {
    top: -1.5%;
  }
}

@media only screen and (max-width: 380px) {
  .AboutBar {
    top: -1%;
    height: 6rem;
  }

  .AboutImage img {
    max-width: 100%;
    height: auto;
  }

  .IconsContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    grid-gap: 4rem;
    gap: 4rem;
  }
}

.IconsContainer a {
  text-decoration: none;
}
.Portfolio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  max-width: 1050px;
  margin: 0 auto;
}

.PortfolioBoxMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.PortfolioBoxMain a {
  text-decoration: none;
}

.PortfolioHeader h6 {
  font-family: "Oswald";
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.4rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.2em;

  color: #dd6e8b;
}

.PortfolioHeader h2 {
  font-family: "Oswald";
  font-style: normal;
  font-weight: normal;
  font-size: 3.2rem;
  line-height: 0rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;

  color: #000000;
  padding-bottom: 2.5rem;
}

.PortfolioBox {
  box-shadow: 0.4rem 0.4rem 1.2rem rgba(0, 0, 0, 0.12);
  border-radius: 0.4rem;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.PortfolioBox img {
  width: 100%;
  min-height: 100%;
  height: auto;
  transition: all 370ms ease-in;
}

#_ImageTitle {
  font-family: "Jost";
  font-size: 1.6rem;
  color: #fff;
  font-weight: 400;
  letter-spacing: 0.2rem;
  border-radius: 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  box-shadow: none;
  width: 85%;
  position: absolute;
  bottom: 1.25rem;
  left: 50%;
  transform: translateX(-50%);

  padding: 1.5rem 0rem;
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(1.5rem);
          backdrop-filter: blur(1.5rem);
}

.PortfolioBox img:hover {
  transform: scale(1.125);
}

.PortfolioBox:hover {
}

.PortfolioHeaderBar {
  width: 6.4rem;
  height: 0.3rem;

  background: #dd6e8b;
}

.PortfolioContent {
  width: 100%;
  height: auto;
  padding: 12rem 0;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10rem;
  gap: 10rem;
  position: relative;
}

.PortfolioContentBottom {
  width: 100%;
  height: auto;
  padding: 0rem 0 12rem 0;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10rem;
  gap: 10rem;
  position: relative;
}

.PortfolioBoxBtn {
  background: #ffffff;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
  max-width: 80%;
  width: 40.2rem;
  height: 7.4rem;
  margin-top: -2.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.3rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;

  color: #000000;
  transition: 0.15s all ease-in;
  cursor: pointer;
}

.PortfolioBoxBtn:hover {
  transform: translateY(-8%);
}

.PortfolioBoxBtn:active {
  transform: translateY(8%);
}

@media only screen and (max-width: 1140px) {
  .Portfolio {
    width: 94%;
  }
  .PortfolioBoxBtn {
    height: 5.4rem;
    margin-top: -2.25rem;
    padding-left: 8%;
    font-size: 1.4rem;
  }
  .PortfolioContent {
    justify-content: space-between;
    padding: 10rem 0rem;
    grid-gap: 0;
    gap: 0;
  }

  .PortfolioContentBottom {
    justify-content: space-between;
    padding: 0rem 0 10rem 0;
    grid-gap: 0;
    gap: 0;
  }
}

@media only screen and (max-width: 900px) {
  .Portfolio {
    width: 81%;
  }

  .PortfolioContent {
    display: flex;
    flex-direction: column;
    padding: 4rem 0rem;
    grid-gap: 4rem;
    gap: 4rem;
  }

  .PortfolioContentBottom {
    display: flex;
    flex-direction: column;
    padding: 0rem 0 4rem 0;
    grid-gap: 4rem;
    gap: 4rem;
  }

  .PortfolioBoxBtn {
    width: 80%;
  }
}

@media only screen and (max-width: 640px) {
  .PortfolioContent {
    display: flex;
    flex-direction: column;
    padding: 6rem 0rem;
    grid-gap: 6rem;
    gap: 6rem;
  }

  .PortfolioContentBottom {
    display: flex;
    flex-direction: column;
    padding: 0rem 0 6rem 0;
    grid-gap: 6rem;
    gap: 6rem;
  }

  .PortfolioBoxBtn {
    height: 6.4rem;
    margin-top: -2.75rem;
    padding-left: 8%;
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 400px) {
  .PortfolioContent {
    display: flex;
    flex-direction: column;
    padding: 5rem 0rem;
    grid-gap: 5rem;
    gap: 5rem;
  }

  .PortfolioContentBottom {
    display: flex;
    flex-direction: column;
    padding: 0rem 0 5rem 0;
    grid-gap: 5rem;
    gap: 5rem;
  }

  .PortfolioBoxBtn {
    height: 6.4rem;
    margin-top: -2.75rem;
    padding-left: 8%;
    font-size: 1.6rem;
  }
}

/* Portfolio Images */
.PortfolioImages {
  position: fixed;
  top: 0%;
  right: 0%;
  max-width: 80vw;
  width: 56.5rem;
  height: 100vh;

  background: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(2.5rem);
          backdrop-filter: blur(2.5rem);
  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem;
  overflow-y: scroll;
}

.PortfolioImages::-webkit-scrollbar-thumb {
  opacity: 1;
  width: 1rem;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #f7c0b9;
  border-radius: 0.8rem;
}

.PortfolioImages::-webkit-scrollbar {
  opacity: 1;
  width: 1.5rem;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #fff;
}

@media only screen and (max-width: 450px) {
  .PortfolioImages::-webkit-scrollbar-thumb {
    display: none;
  }

  .PortfolioImages::-webkit-scrollbar {
    display: none;
  }
}

.Testimonials {
    width: 100%;
    height: auto;
    width: 94%;
    max-width: 1050px;
    margin: 0 auto;
  }
  
  .TestimonialsHeader h6 {
    font-family: "Oswald";
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2.4rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.2em;
  
    color: #dd6e8b;
  }
  
  .TestimonialsHeader h2 {
    font-family: "Oswald";
    font-style: normal;
    font-weight: normal;
    font-size: 3.2rem;
    line-height: 0rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
  
    color: #000000;
    padding-bottom: 2.5rem;
  }
  
  .TestimonialsHeaderBar {
    width: 6.4rem;
    height: 0.3rem;
  
    background: #dd6e8b;
  }
  
  .TestimonialsContent {
    width: 100%;
    height: auto;
    padding: 12rem 0;
    margin: 0 auto;
  
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10rem;
    gap: 10rem;
    position: relative;
  }
  
  .TestimonialsCards {
    width: 100%;
    height: auto;
  
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 6rem;
    gap: 6rem;
    padding: 5rem 0;
  }
  
  .TestimonialsCard {
    width: 40%;
    height: auto;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
  }
  
  .TestimonialsCardText {
    width: 50%;
    min-height: 55rem;
    height: auto;
  
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 0.8rem;
    background-color: #fffbf9;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    padding: 10rem 2rem;
    position: relative;
  }
  
  .TestimonialsCard .Card {
    width: 100%;
    height: 11.1rem;
  
    background: #c4c4c4;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 0.8rem;
    background-color: #d5d6ea;
  
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 2rem;
    grid-gap: 2rem;
    gap: 2rem;
  
    color: #000000;
    font-family: "Oswald";
  }
  
  .CardImg {
    width: 7.9rem;
    height: 7.9rem;
    border-radius: 50%;
    background-color: aqua;
    overflow: hidden;
  }
  
  .CardImg img {
    width: 100%;
    height: auto;
  }
  
  .Card h2 {
    margin: 0;
    font-size: 1.4rem;
    font-style: normal;
  
    letter-spacing: 0.1em;
  
    color: #000000;
    font-weight: 400;
  }
  
  .Card {
    transition: 0.2s all ease;
  }
  
  .Card:hover {
    transform: translateX(-5rem);
  }
  
  .Card p {
    margin: 0;
    font-size: 1.3rem;
    font-weight: 300;
    letter-spacing: 0.1em;
  }
  
  .CardText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  
  .TestimonialsCardText .TestimonialsAllText {
    min-width: 25rem;
    width: 80%;
  
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.05em;
  
    color: #343738;
  }
  
  .TestimonialsCardText p {
    position: absolute;
    top: 2rem;
    left: 4rem;
  }
  
  @media only screen and (max-width: 1140px) {
    .Testimonials {
      width: 94%;
    }
  }
  
  @media only screen and (max-width: 900px) {
    .Testimonials {
      width: 81%;
    }
  
    .TestimonialsCards {
      width: 100%;
      height: auto;
  
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      grid-gap: 6rem;
      gap: 6rem;
      padding: 5rem 0;
    }
  
    .TestimonialsCard {
      width: 80%;
    }
  
    .TestimonialsCardText {
      width: 80%;
    }
  }
  
  @media only screen and (max-width: 450px) {
  
    .TestimonialsCard {
      width: 95%;
    }
  
    .TestimonialsCardText {
      width: 100%;
    }
  }
  
.Map {
  width: 40rem;
  height: 29rem;
  background-color: #fff;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
  position: relative;
  background-image: url("https://source.unsplash.com/BJXAxQ1L7dI");
  background-size: cover;
  max-width: 100%;
}

.MapScreen {
  position: absolute;
  top: 0%;
  left: 0%;
  background-color: rgba(0, 0, 0, 0.12);
  width: 100%;
  min-height: 100%;
}

.Map button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.5rem 2.75rem;
  outline: none;
  border: none;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.1rem;
  color: #fff;
  background-color: #dd6e8b;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);

  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  cursor: pointer;
}



@media only screen and (max-width: 450px) {
  .Map {
    height: 25rem;
  }
}

.Contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  max-width: 1050px;
  margin: 0 auto;
}

.ContactHeader h6 {
  font-family: "Oswald";
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.4rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.2em;

  color: #dd6e8b;
}

.ContactHeader h2 {
  font-family: "Oswald";
  font-style: normal;
  font-weight: normal;
  font-size: 3.2rem;
  line-height: 0rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;

  color: #000000;
  padding-bottom: 2.5rem;
}

.ContactHeaderBar {
  width: 6.4rem;
  height: 0.3rem;

  background: #dd6e8b;
}

.ContactContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 1140px) {
  .Contact {
    width: 94%;
  }
}

@media only screen and (max-width: 1140px) {
  .Contact {
    width: 81%;
  }
}

@media only screen and (max-width: 1060px) {
  .ContactContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

.Form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12rem 0rem;
  grid-gap: 2rem;
  gap: 2rem;
}

.Form input {
  width: 40rem;
  max-width: 80vw;
  padding: 1.5rem 2.75rem;
  outline: none;
  border: none;
  border-bottom: 2px solid #dd6e8b;
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 0.2rem;
}

.Form button {
  margin-top: 1.5rem;
  padding: 1.25rem 2.75rem;
  outline: none;
  border: none;
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.2rem;
  color: #fff;
  background-color: #dd6e8b;
  cursor: pointer;
}

.Form .MsgSucess {
  margin-top: 1.5rem;
  padding: 1.25rem 2.75rem;
  outline: none;
  border: none;
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.2rem;
  color: #fff;
  background-color: #dd6e8b;
}

.SucessContext {
  font-size: 1.5rem;
  font-weight: 500;
  color: #dd6e8b;
}

@media only screen and (max-width: 1140px) {
  .Form {
    padding: 10rem 0rem;
  }
}

@media only screen and (max-width: 1140px) {
  .Form {
    padding: 8rem 0rem;
  }
}

.Footer {
    width: 100%;
    min-height: 14rem;
    background-color: #fff;
    margin-top: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 20px 6px 12px rgba(0, 0, 0, 0.4);
  }

  .ScrollTop {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 5rem;
    height: 5rem;
    background-color: #fff;
    box-shadow: 0.6rem 0.6rem 1.2rem rgba(0, 0, 0, 0.12);
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    z-index: 9999999999999;
  }
  
  .FooterContent {
    max-width: 1064px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    font-family: "Jost";
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.1rem;
    color: rgba(0, 0, 0, 0.7);
  }

  .FooterContent div img {
      width: 9rem;
      height: 5.383rem;
  }
  
  @media only screen and (max-width: 1140px) {
      .FooterContent {
        width: 94%;
      }
    }
    
    @media only screen and (max-width: 1140px) {
      .FooterContent {
        width: 81%;
      }
    }
    
    
    @media only screen and (max-width: 1060px) {
      .FooterContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-gap: 2rem;
        gap: 2rem;
      }
  
      .Footer {
          height: 18rem;
      }
    }
  
._PortfolioPage {
  background-color: rgba(221,110,139, 0.18);
  min-height: 100vh;
  height: auto;
}

._PortfolioPageLogo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 0rem;
    padding-bottom: 2.75rem;
}



.Controls {
  width: auto;
  height: 5rem;
  box-shadow: 0.6rem 0.6rem 1.2rem rgba(0, 0, 0, 0.12);
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 2.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
}

.Controls div {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 3.5rem;
  background-color: #dd6e8b;
  height: 3.5rem;
  border-radius: 50%;
  box-shadow: 0.6rem 0.6rem 1.2rem rgba(0, 0, 0, 0.12);
}

._PortfolioPageLogo img {
    width: 12.75rem;
    height: auto;
}

._PortfolioPageFooter {
  margin: 0;
  font-weight: 500;
  font-family: 'Jost';
  letter-spacing: 0.1rem;
  font-size: 1.6rem;
  padding-top: 8rem;
  padding-bottom: 4rem;
  text-align: center;
  color: #dd6e8b;
}

._PortfolioPageHeader {
  font-size: 2.2rem;
  font-family: "Jost";
  letter-spacing: 0.1rem;
  padding-bottom: 2rem;
  color: #dd6e8b;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

._PortfolioPageHeader span {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scaleX(-1);
}


.gallery {
  -webkit-column-count: 4;
  column-count: 4;
  -webkit-column-width: 25%;
  column-width: 25%;
  padding: 0 1.2rem;
}

.gallery .pics {
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 1.2rem;
}

.gallery .pics:hover {
    -webkit-filter: opacity(0.8);
            filter: opacity(0.8);
}

@media (max-width: 991px) {
  .gallery {
    -webkit-column-count: 2;
    column-count: 2;
  }
}


/* Model */ 

.model  {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
  visibility: visible;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 9999;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 2rem 0 2rem;
  margin: 0 auto;
}

.model.open #ClosePortfolioIcon {
  position: fixed;
  top: 2rem;
  right: 4rem;
  cursor: pointer;
  z-index: 99999999;
}


.PortfolioExplainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem;
  margin-bottom: 6rem;
}

.PortfolioExplainer div {
  max-width: 60rem;
  font-family: "Jost";
  font-size: 1.7rem;
  text-align: center;
  letter-spacing: 0.1rem;
  line-height: 2rem;
}

@media (max-width: 480px) {
  .gallery {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    column-width: 100%;
  }

  .PortfolioExplainer div {
    text-align: left;
  }
}
